import {TextField, TextFieldProps} from "@mui/material";
import React, { useState } from "react";
import "./css/CCTextField.scss";

type CCTextFieldLocalProps = {
    onValidate?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

type CCTextFieldProps = CCTextFieldLocalProps & TextFieldProps

export default function CCTextField({
    fullWidth,
    size,
    margin,
    variant,
    onChange,
    onBlur,
    onValidate,
    InputProps,
    inputProps = {},
    children,
    label,
    ...props
}: CCTextFieldProps) {

    const [blurred, setBlurred] = useState(false);

    size = size  ? size : label ? "medium" : "small";
    margin = margin  ? margin : label ? "normal" : "none";

    if (InputProps && InputProps.autoComplete === undefined) InputProps.autoComplete = "off";

    if (InputProps) {
        InputProps.inputProps = inputProps = {
            ...(InputProps.inputProps ?? {}),
            ...(inputProps ?? {}),
        };
    }

    if (inputProps.autoCapitalize === undefined && inputProps.autocapitalize === undefined) inputProps.autoCapitalize = "none";
    if (inputProps.autoComplete === undefined && inputProps.autocomplete === undefined) inputProps.autoComplete = "off";
    if (inputProps.spellCheck === undefined && inputProps.spellcheck === undefined) inputProps.spellCheck = "false";

    return (
        <TextField
            fullWidth={fullWidth ? fullWidth : true}
            size={size}
            margin={margin}
            variant={variant}
            InputProps={InputProps}
            inputProps={inputProps} // same as InputProps['inputProps']
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (onChange) onChange(e);
                if (onValidate) {
                    const value = e.target.value;
                    const shouldValidate = blurred;
                    if (value.length === 0) setBlurred(false);
                    if (shouldValidate) onValidate(e);
                }
            }}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                if (onBlur) onBlur(e);
                if (onValidate) {
                    const value = e.target.value;
                    const validated = blurred;
                    if (value.length > 0) setBlurred(true);
                    if (!validated) onValidate(e);
                }
            }}
            {...props}
        >
            {children}
        </TextField>
    );
}
