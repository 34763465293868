import {Button, ButtonProps} from "@mui/material";
import {
    Blue2,
    Blue3,
    Blue5,
    Green2,
    Green3, Green4,
    Grey2,
    Grey3,
    Grey4,
    Grey8,
    Red2,
    Red3,
    Red4,
    White, Yellow2, Yellow3, Yellow4
} from "../../theme/styles";
import CCPulse from "./CCPulse";
import css_self from "./css/CCButton.module.scss";

const commonCSS = {
    margin: { xs: "0 6px 10px 0", sm: "0 10px 10px 10px" },
    fontWeight: 400,
    fontSize: "14px",
    borderRadius: "100px",
    minWidth: {xs: "auto", sm: "136px"},
    "&:disabled": {
        color: Grey3,
        cursor: "not-allowed",
        pointerEvents: "all",
    },
}

type CCButtonLocalProps = {
    animate?: boolean,
    color?: "primary" | "secondary" | "red" | "red-outline" | "yellow" | "green"
}

type CCButtonProps = Omit<ButtonProps, "color"> & CCButtonLocalProps

export default function CCButton({
    color,
    variant,
    animate,
    children,
    onClick,
    ...props
}: CCButtonProps) {
    const basicButton = {
        color: White,
        backgroundColor: Blue3,
        "&:hover": {
            backgroundColor: Blue2,
        },
        "&:active": {
            backgroundColor: Blue5,
        },
        "&:disabled": {
            backgroundColor: Grey2,
        },
    }
    const secondaryButton = {
        color: Grey8,
        backgroundColor: White,
        borderColor: Grey4,
        "&:hover": {
            backgroundColor: White,
            color: Blue2,
            borderColor: Blue2,
        },
        "&:active": {
            borderColor: Blue3,
            color: Red4,
        },
        "&:disabled": {
            backgroundColor: White,
            borderColor: Grey3,
        },
    }
    const greenButton = {
        color: White,
        backgroundColor: Green3,
        "&:hover": {
            backgroundColor: Green2,
        },
        "&:active": {
            backgroundColor: Green4,
        },
        "&:disabled": {
            backgroundColor: Grey2,
        },
    }
    const yellowButton = {
        color: Grey8,
        backgroundColor: Yellow3,
        "&:hover": {
            backgroundColor: Yellow2,
        },
        "&:active": {
            backgroundColor: Yellow4,
        },
        "&:disabled": {
            backgroundColor: Grey2,
        },
    }
    const redButton = {
        color: White,
        backgroundColor: Red3,
        "&:hover": {
            backgroundColor: Red2,
        },
        "&:active": {
            backgroundColor: Red4,
        },
        "&:disabled": {
            backgroundColor: Grey2,
        },
    }
    const redOutlinedButton = {
        color: Red3,
        borderColor: Red3,
        backgroundColor: White,
        "&:hover": {
            backgroundColor: Red2,
            color: White,
        },
        "&:active": {
            backgroundColor: Red4,
        },
        "&:disabled": {
            backgroundColor: Grey2,
        },
    }
    function getButton () {
        switch(color){
            case "primary":
                return basicButton
            case "secondary":
                return secondaryButton
            case "yellow":
                return yellowButton
            case "green":
                return greenButton
            case "red":
                return redButton
            case "red-outline":
                return redOutlinedButton
            default: {}
        }
    }

    let rest = props;

    if (animate) onClick = () => null;

    return (
        <Button
            {...{"data-cc-component": "CCButton"}}
            {...{"data-animate": animate}}
            variant={variant}
            onClick={animate ? undefined : onClick}
            sx={{...getButton(), ...commonCSS}}
            {...rest}
        >
            <span style={animate ? {visibility: "hidden"} : undefined}>{children}</span>
            {animate ? <CCPulse className={css_self.Pulse} DotProps={undefined} /> : undefined}
        </Button>
    );
}
