import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import "./css/CCTextField.scss";

export default function CCDateTimePicker({
    format,
    minDate,
    onChange,
    value,
    label,
    ...props
}: {
    minDate?: moment.Moment;
    onChange?: (date: Date) => void;
    value?: moment.Moment;
    format?: any;
    label?: string;
}) {
    format = format !== undefined ? format : "MM/DD/YYYY HH:mm";

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
                format={format}
                minDate={minDate}
                ampm={false}
                onChange={onChange ? (date: any) => {
                    if (date) {
                        date = date.format(format); // date will be correct time, but wrong timezone (utc instead of local)
                        date = moment(date, format); // convert the parsed time above minus utc back into a moment to force local
                        date = date.utc().format(); // convert the corrected moment above into utc string (iso 8601)
                    }
                    onChange(date);
                } : undefined}
                value={value}
                label={label}
                {...props}
            />
        </LocalizationProvider>
    );
}
