import React from "react";

import Applet from "../../Applet";
import DeveloperLogTable from "../../../../components/LogManagement/DeveloperLogTable";

class DeveloperLogTableApplet extends Applet {

    render() {
        return <DeveloperLogTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Logs"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state = {
        Name: "DeveloperLogTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 45,
        PageSize: {
            "default": 40,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 40,
            "lx": 40,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "EventType",
                },
                {
                    id: "EventCode",
                },
                {
                    id: "EventDatetime",
                }
            ]
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "EventCode": {
                id: "EventCode",
                width: 150,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Code",
            },
            "EventType": {
                id: "EventType",
                width: 500,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Type",
            },
            "EventDatetime": {
                id: "EventDatetime",
                width: 260,
                cellRenderer: "Event Datetime Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Date Time",
            }
        }
    };
}

export default DeveloperLogTableApplet;
