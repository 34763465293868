import {InputLabel, InputLabelProps} from "@mui/material";
import "./css/CCInputLabel.scss";



export default function CCInputLabel
({
     children,
     ...props
}:
    InputLabelProps
) {
    return (
        <InputLabel {...{"data-cc-component": "CCInputLabel"}} {...props}>
            {children}
        </InputLabel>
    );
}
