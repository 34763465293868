import {useCallback, useReducer} from "react";

// some callbacks like with MQTT message listeners (backed by EventEmitter) will freeze state values
// and not use the latest unless we use this reducer/callback hack. an example
// would be if the sessionContext state changes, the callback would only ever see
// the values that were a part of the original state, and not any updates.
export function useHandler(f: any, deps: any[]) {

    const [, handler] = useReducer(
        useCallback(
            (_, ...props) => f(...props),
            // eslint-disable-next-line react-hooks/exhaustive-deps
            deps,
        ),
        undefined
    );

    return handler;
}
