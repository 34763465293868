export const NAV_TYPE_BACK_FORWARD = "back_forward";
export const NAV_TYPE_RELOAD = "reload";
export const NAV_TYPE_NAVIGATE = "navigate";

export function wasPageReloaded() {
    return wasPageNavType(NAV_TYPE_RELOAD);
}

export function wasPageNavType(type: any) {

    if (window.performance) {

        const entries = window.performance.getEntriesByType("navigation");

        return (
            (entries.length > 0 && entries[0].entryType === type) || (
                window.performance.navigation?.type === (
                    type === NAV_TYPE_BACK_FORWARD ? window.performance.navigation.TYPE_BACK_FORWARD :
                        type === NAV_TYPE_RELOAD ? window.performance.navigation.TYPE_RELOAD :
                            type === NAV_TYPE_NAVIGATE ? window.performance.navigation.TYPE_NAVIGATE :
                                undefined
                )
            )
        );
    }

    return undefined;
}

export function getPageTimings() {

    if (window.performance) {

        const timing = window.performance.timing;

        return {
            start: timing.navigationStart,
            dns: timing.domainLookupEnd - timing.domainLookupStart,
            tcp: timing.connectEnd - timing.connectStart,
            ssl: timing.secureConnectionStart !== 0 ? timing.requestStart - timing.secureConnectionStart : undefined,
            ttfb: timing.responseStart - timing.navigationStart,
            page: timing.domComplete - timing.navigationStart,
            dom: timing.domContentLoadedEventEnd - timing.navigationStart,
        };
    }

    return undefined;
}

export function parseQueryParams(query: any) {
    return Object.fromEntries(
        query
            .substring(1)
            .split("&")
            .map((i: any) => {
                const [k, ...v] = i.split("=");
                return [k, v.join("=")];
            }),
    );
}