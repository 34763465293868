import {Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import "./css/CCTextField.scss";
import moment from "moment-timezone";

export default function CCDatePicker({
    format,
    minDate,
    onChange,
    value,
    helperText,
    label
}: {
    minDate?: moment.Moment;
    onChange?: (date: Date) => void;
    value?: moment.Moment;
    format?: any;
    helperText?: string;
    label?: string;
}) {
    format = format ? format : "MM/DD/YYYY";
    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <div>
                <DatePicker
                    format={format}
                    minDate={minDate}
                    onChange={onChange ? (date: any) => {
                        date = date ? date.format("YYYY-MM-DD") : null;
                        return onChange(date);
                    }: undefined}
                    label={label}
                    value={value}
                 />
                {helperText && <div><Typography style={{fontSize: 14, fontWeight: 400, color: "#FF0000", marginLeft: "16px", marginTop: "2px"}}>{helperText}</Typography></div>}
            </div>
        </LocalizationProvider>
    );
}
