import React, {useEffect} from "react";
import EnterpriseObject from "../eoec/eo/EnterpriseObject";
import enterpriseObjectClassFactory from "../eoec/EnterpriseObjectClassFactory";
import {Box, Button, Drawer, IconButton, Popover, Switch, Typography} from "@mui/material";
import {Api, Apps, CellTower, DeveloperMode, ErrorOutline, ManageAccounts, Menu as MenuIcon, MonitorHeart} from "@mui/icons-material";
import {Blue3, Grey6} from "../theme/styles";
import css_self from "./css/Pages.module.scss";
import useBreakpoint from "../util/useBreakpoint";
import memoryContext from "../util/MemoryContext";
import DeveloperEventTableApplet from "../eoec/applet/impl/EventManagement/DeveloperEventTableApplet";
import DeveloperEndpointTableApplet from "../eoec/applet/impl/EndpointManagement/DeveloperEndpointTableApplet";
import DeveloperEndpointConfigurationTableApplet from "../eoec/applet/impl/EndpointManagement/DeveloperEndpointConfigurationTableApplet";
import DeveloperEventConfigurationTableApplet from "../eoec/applet/impl/EventManagement/DeveloperEventConfigurationTableApplet";
import DeveloperApplicationTableApplet from "../eoec/applet/impl/RestManagement/DeveloperApplicationTableApplet";
import DeveloperCredentialTableApplet from "../eoec/applet/impl/RestManagement/DeveloperCredentialTableApplet";
import DeveloperCredentialAuthorizationTableApplet from "../eoec/applet/impl/RestManagement/DeveloperCredentialAuthorizationTableApplet";
import DeveloperCredentialAuthorizationDetailTableApplet from "../eoec/applet/impl/RestManagement/DeveloperCredentialAuthorizationDetailTableApplet";
import DeveloperExceptionTableApplet from "../eoec/applet/impl/ExceptionManagement/DeveloperExceptionTableApplet";
import DeveloperLogTableApplet from "../eoec/applet/impl/LogManagement/DeveloperLogTableApplet";


type MenuSelection = {id: string, target: EventTarget & HTMLButtonElement}

export default function Dashboard() {
    const breakpoint = useBreakpoint();

    // @ts-ignore
    let memoryState = memoryContext[`Dashboard`];

    let developerEndpointEnterpriseObject: EnterpriseObject | undefined;
    let developerEventEnterpriseObject: EnterpriseObject | undefined;
    let developerApplicationEnterpriseObject: EnterpriseObject | undefined;
    let developerExceptionEnterpriseObject: EnterpriseObject | undefined;
    let developerLogEnterpriseObject: EnterpriseObject | undefined;

    if (memoryState.developerEndpointEnterpriseObject === undefined || memoryState.developerEndpointEnterpriseObject === null) {
        developerApplicationEnterpriseObject = enterpriseObjectClassFactory("DeveloperApplication") as EnterpriseObject;
        developerEndpointEnterpriseObject = enterpriseObjectClassFactory("DeveloperEndpoint") as EnterpriseObject;
        developerEventEnterpriseObject = enterpriseObjectClassFactory("DeveloperEvent") as EnterpriseObject;
        developerExceptionEnterpriseObject = enterpriseObjectClassFactory("DeveloperException") as EnterpriseObject;
        developerLogEnterpriseObject = enterpriseObjectClassFactory("DeveloperLog") as EnterpriseObject;

        memoryState.developerApplicationEnterpriseObject = developerApplicationEnterpriseObject;
        memoryState.developerEndpointEnterpriseObject = developerEndpointEnterpriseObject;
        memoryState.developerEventEnterpriseObject = developerEventEnterpriseObject;
        memoryState.developerExceptionEnterpriseObject = developerExceptionEnterpriseObject;
        memoryState.developerLogEnterpriseObject = developerLogEnterpriseObject;
    } else {
        developerApplicationEnterpriseObject = memoryState.developerApplicationEnterpriseObject;
        developerEndpointEnterpriseObject = memoryState.developerEndpointEnterpriseObject;
        developerEventEnterpriseObject = memoryState.developerEventEnterpriseObject;
        developerExceptionEnterpriseObject = memoryState.developerExceptionEnterpriseObject;
        developerLogEnterpriseObject = memoryState.developerLogEnterpriseObject;
    }

    const [drawerVisibility, setDrawerVisibility] = React.useState<boolean>(false);
    const [dashboardMenu, setDashboardMenu] = React.useState(1);
    const [menu, setMenu] = React.useState<MenuSelection | null>(null);

    useEffect(() => {

        return () => {
            // memoryState.developerExceptionEnterpriseObject = undefined;
            // memoryState.developerEventEnterpriseObject = undefined;
            // memoryState.developerApplicationEnterpriseObject = undefined;
        }
    });

    let contents

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerVisibility(newOpen);
    };

    if (breakpoint.getBreakPointName() !== "xl" && breakpoint.getBreakPointName() !== "lg") {
        contents = (
            <div>
                <Drawer style={{backgroundColor: "#FAFAFA"}} open={drawerVisibility} onClose={toggleDrawer(!drawerVisibility)}>
                    <div style={{marginTop: breakpoint.getBreakPointName() !== "xl" && breakpoint.getBreakPointName() !== "lg" ? "100px": "0px", paddingLeft: "5px", paddingTop: "2px"}}>
                    </div>
                </Drawer>
            </div>
        )
    }

    let restAPIManagement = null;
    if (dashboardMenu === 1) {
        restAPIManagement = (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #EFEFEF", paddingBottom: "15px", height: "40px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div style={{paddingTop: "10px", marginLeft: "35px"}}>
                            <div>
                                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => setDashboardMenu(1)}>REST API Management</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", height: "100vh", paddingRight: "60px"}}>
                    {breakpoint.getBreakPointName() === "lg" || breakpoint.getBreakPointName() === "xl" ? (
                        <>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperApplicationTableApplet enterpriseComponent={developerApplicationEnterpriseObject?.getEnterpriseComponent("DeveloperApplication")}/>
                            </div>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperCredentialTableApplet enterpriseComponent={developerApplicationEnterpriseObject?.getEnterpriseComponent("DeveloperCredential")}/>
                            </div>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperCredentialAuthorizationTableApplet enterpriseComponent={developerApplicationEnterpriseObject?.getEnterpriseComponent("DeveloperCredentialAuthorization")}/>
                            </div>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperCredentialAuthorizationDetailTableApplet enterpriseComponent={developerApplicationEnterpriseObject?.getEnterpriseComponent("DeveloperCredentialAuthorizationDetail")}/>
                            </div>
                        </>
                    ) : (
                        <div style={{paddingLeft: "5px", width: "48px", height: "100vh", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA"}}>
                            {drawerVisibility ? contents : (<Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}} onClick={() => setDrawerVisibility(!drawerVisibility)}/>}/>)}
                        </div>
                    )}
                </div>
            </>
        )
    }

    let endpointManagement = null;
    if (dashboardMenu === 2) {
        endpointManagement = (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #EFEFEF", paddingBottom: "15px", height: "40px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div style={{paddingTop: "10px", marginLeft: "35px"}}>
                            <div>
                                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => setDashboardMenu(1)}>Endpoint Management</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", height: "100vh", paddingRight: "60px"}}>
                    {breakpoint.getBreakPointName() === "lg" || breakpoint.getBreakPointName() === "xl" ? (
                        <>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperEndpointTableApplet enterpriseComponent={developerEndpointEnterpriseObject?.getEnterpriseComponent("DeveloperEndpoint")}/>
                            </div>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperEndpointConfigurationTableApplet enterpriseComponent={developerEndpointEnterpriseObject?.getEnterpriseComponent("DeveloperEndpointConfiguration")}/>
                            </div>
                        </>
                    ) : (
                        <div style={{paddingLeft: "5px", width: "48px", height: "100vh", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA"}}>
                            {drawerVisibility ? contents : (<Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}} onClick={() => setDrawerVisibility(!drawerVisibility)}/>}/>)}
                        </div>
                    )}
                </div>
            </>
        )
    }

    let eventManagement = null;
    if (dashboardMenu === 3) {
        eventManagement = (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #EFEFEF", paddingBottom: "15px", height: "40px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div style={{paddingTop: "10px", marginLeft: "35px"}}>
                            <div>
                                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => setDashboardMenu(1)}>Event Management</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", height: "100vh", paddingRight: "60px"}}>
                    {breakpoint.getBreakPointName() === "lg" || breakpoint.getBreakPointName() === "xl" ? (
                        <>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperEventTableApplet enterpriseComponent={developerEventEnterpriseObject?.getEnterpriseComponent("DeveloperEvent")}/>
                            </div>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperEventConfigurationTableApplet enterpriseComponent={developerEventEnterpriseObject?.getEnterpriseComponent("DeveloperEventConfiguration")}/>
                            </div>
                        </>
                    ) : (
                        <div style={{paddingLeft: "5px", width: "48px", height: "100vh", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA"}}>
                            {drawerVisibility ? contents : (<Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}} onClick={() => setDrawerVisibility(!drawerVisibility)}/>}/>)}
                        </div>
                    )}
                </div>
            </>
        )
    }

    let monitoringManagement = null;
    if (dashboardMenu === 4) {
        monitoringManagement = null;
    }

    let exceptionManagement = null;
    if (dashboardMenu === 5) {
        exceptionManagement = (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #EFEFEF", paddingBottom: "15px", height: "40px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div style={{paddingTop: "10px", marginLeft: "35px"}}>
                            <div>
                                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => setDashboardMenu(5)}>Exception Management</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", height: "100vh", paddingRight: "60px"}}>
                    {breakpoint.getBreakPointName() === "lg" || breakpoint.getBreakPointName() === "xl" ? (
                        <>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperExceptionTableApplet enterpriseComponent={developerExceptionEnterpriseObject?.getEnterpriseComponent("DeveloperException")}/>
                            </div>
                        </>
                    ) : (
                        <div style={{paddingLeft: "5px", width: "48px", height: "100vh", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA"}}>
                            {drawerVisibility ? contents : (<Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}} onClick={() => setDrawerVisibility(!drawerVisibility)}/>}/>)}
                        </div>
                    )}
                </div>
            </>
        )
    }

    let sandboxManagement = null;
    if (dashboardMenu === 6) {
        sandboxManagement = null;
    }

    let logManagement = null;
    if (dashboardMenu === 7) {
        logManagement = (
            <>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: "1px solid #EFEFEF", paddingBottom: "15px", height: "40px"}}>
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
                        <div style={{paddingTop: "10px", marginLeft: "35px"}}>
                            <div>
                                <Button className={css_self.NavMenuButton} variant="text" onClick={(e) => setDashboardMenu(5)}>Log Management</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display: "flex", flexDirection: "column", backgroundColor: "#FFFFFF", height: "100vh", paddingRight: "60px"}}>
                    {breakpoint.getBreakPointName() === "lg" || breakpoint.getBreakPointName() === "xl" ? (
                        <>
                            <div style={{paddingLeft: "30px", paddingTop: "30px", width: "100%"}}>
                                <DeveloperLogTableApplet enterpriseComponent={developerLogEnterpriseObject?.getEnterpriseComponent("DeveloperLog")}/>
                            </div>
                        </>
                    ) : (
                        <div style={{paddingLeft: "5px", width: "48px", height: "100vh", borderRight: "1px solid #DDDDDD", backgroundColor: "#FAFAFA"}}>
                            {drawerVisibility ? contents : (<Button className={css_self.PagesButton} variant="text" startIcon={<MenuIcon sx={{width: 28, height: 35, color: Grey6}} onClick={() => setDrawerVisibility(!drawerVisibility)}/>}/>)}
                        </div>
                    )}
                </div>
            </>
        )
    }


    return (
        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", height: 52, backgroundColor: "#1498D8"}}>
                <div style={{paddingTop: "9px", paddingLeft: "20px"}}>
                    <Typography style={{fontSize: 24, fontWeight: 400, color: "#FFFFFF"}}>Dashboard</Typography>
                </div>
                <div>
                    <IconButton className={css_self.DashboardMenuButton} onClick={(e) => setMenu({id: "apps", target: e.currentTarget})}>
                        <Apps/>
                    </IconButton>{menu?.id === "apps" ? (
                    <Popover anchorEl={menu.target}
                             anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                             transformOrigin={{vertical: -12, horizontal: "left"}}
                             open={true}
                             onClose={() => setMenu(null)}
                             TransitionProps={{
                                 onExited: () => setMenu(null),
                             }}
                             PaperProps={{
                                 elevation: 2,
                             }}
                    >
                        <Box sx={{p: 2, bgcolor: "background.paper", width: "375px", height: "375px"}}>
                            <div style={{display: "flex", flexDirection: "column", padding: "10px"}}>
                                <div style={{display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        setMenu(null)
                                                        setDashboardMenu(1)
                                                    }}
                                        >
                                            <ManageAccounts sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>REST API Management</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        setMenu(null)
                                                        setDashboardMenu(2)
                                                    }}
                                        >
                                            <Api sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Endpoint Management</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        setMenu(null)
                                                        setDashboardMenu(3)
                                                    }}
                                        >
                                            <CellTower sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Event Management</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        setMenu(null)
                                                        setDashboardMenu(4)
                                                    }}
                                        >
                                            <MonitorHeart sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Monitoring</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        setMenu(null)
                                                        setDashboardMenu(5)
                                                    }}
                                        >
                                            <ErrorOutline sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Exception Management</Typography>
                                    </div>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        setMenu(null)
                                                        setDashboardMenu(6)
                                                    }}
                                        >
                                            <DeveloperMode sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Sandbox Management</Typography>
                                    </div>
                                </div>
                                <div style={{marginTop: "20px", display: "flex", flexDirection: "row", alignContent: "flex-start"}}>
                                    <div style={{width: "125px", display: "flex", flexDirection: "column", justifyContent: "center", marginLeft: "auto", marginRight: "auto"}}>
                                        <IconButton className={css_self.AppMenuButton}
                                                    onClick={() => {
                                                        setMenu(null)
                                                        setDashboardMenu(7)
                                                    }}
                                        >
                                            <MonitorHeart sx={{color: Blue3, width: 45, height: 45}}/>
                                        </IconButton>
                                        <Typography style={{fontSize: "14px", fontWeight: "400", color: "#000000", textAlign: "center"}}>Log Management</Typography>
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Popover>
                ) : null}
                </div>
            </div>
            {restAPIManagement}
            {endpointManagement}
            {eventManagement}
            {monitoringManagement}
            {exceptionManagement}
            {logManagement}
            {sandboxManagement}
        </div>
    );
}