import axios from "axios";
import * as Analytics from "./Analytics";
import {SessionContext} from "./SessionContext";

export function makeEnlilAxios(sessionContext: any) {

    // may be a dict, null or a SessionContext object depending on login completion status
    if (!sessionContext) sessionContext = new SessionContext();

    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        // metadata: {},
    });

    instance.interceptors.request.use(
        (config: any) => {

            if (config.metadata === undefined) {
                config.metadata = {};
                config.metadata.startTime = null;
                config.metadata.endTime = null;
            }
            config.metadata.startTime = new Date();

            if (config.url !== "t2/v1/log") {
                Analytics.record("API Request", {
                    "HTTP URL": config.url,
                    "HTTP Method": config.method,
                });
            }

            const token = sessionContext.getAccessToken();
            if (token) config.headers["X-Enlil-AccessToken"] = token;

            return config;
        },
        (error) => Promise.reject(error),
    );

    instance.interceptors.response.use(
        (response: any) => {

            // Sometime response.data is string instead of object #1723
            // https://github.com/axios/axios/issues/1723
            if (response.headers["content-type"]?.includes("application/json")) {
                if (typeof response.data === "string" && response.data !== "") {
                    try {
                        response.data = JSON.parse(
                            response.data
                                .replace(/\\/g, "\\\\")
                                .replace(/\n/g, "\\n")
                                .replace(/\r/g, "\\r")
                                .replace(/\\"/g, "\""),
                        );
                    } catch (e) {
                        console.error(e);
                    }
                }
            }

            const config = response.config;

            config.metadata.endTime = new Date();

            if (config.url !== "t2/v1/log") {
                Analytics.record("API Response", {
                    "HTTP URL": config.url,
                    "HTTP Method": config.method,
                    "HTTP Code": response.status,
                    "HTTP Duration": (config.metadata.endTime - config.metadata.startTime) / 1000,
                    "Reason Code": response.data ? response.data.Code : undefined,
                });
            }

            return response;
        },
        (error) => {

            const config = error.config;
            const response = error.response;

            config.metadata.endTime = new Date();

            // response can be undefined when there is a network issue / no connection
            if (response === undefined) {
                return Promise.reject(error);
            }

            if (config.url !== "t2/v1/log") {
                Analytics.record("API Response", {
                    "HTTP URL": config.url,
                    "HTTP Method": config.method,
                    "HTTP Code": response.status,
                    "HTTP Duration": (config.metadata.endTime - config.metadata.startTime) / 1000,
                    "Reason Code": response.data ? response.data.Code : undefined,
                });
            }

            if (config.metadata.disableRefresh !== true) {
                if (config.metadata.cogntioRefreshed === undefined) {

                    config.metadata.cogntioRefreshed = true;

                    if (response.status === 401) {
                        return axios({
                            method: "PUT",
                            url: `${process.env.REACT_APP_API_URL}/t2/v1/authenticate`,
                            data: {
                                RefreshToken: sessionContext.getRefreshToken(),
                            },
                        }).then((response) => {
                            const token = response.data.AccessToken;
                            sessionContext.setAccessToken(token);
                            config.headers["X-Enlil-AccessToken"] = token;
                            return axios(config);
                        });
                    }
                }
            }

            return Promise.reject(error);
        },
    );

    return instance;
}

const EnlilAxios = makeEnlilAxios(null);

export default EnlilAxios;
