import {TextField, TextFieldProps} from "@mui/material";
import React, {useState} from "react";
import * as formatters from "../../util/Formatters";
import "./css/CCInputLabel.scss";

type CCInputPhoneLocalProps = {
    value?: any;
}

type CCInputPhoneProps = CCInputPhoneLocalProps & TextFieldProps;

export default function CCInputPhone({
    fullWidth,
    variant,
    value: inheritedValue,
    onChange,
    onBlur,
    children,
    onBeforeInputCapture, // No usage
    ...props
}: CCInputPhoneProps) {

    const [phoneValue, setPhoneValue] = useState("");

    return (
        <TextField
            fullWidth={fullWidth}
            variant={variant ? variant : "outlined"}
            value={phoneValue ? phoneValue : inheritedValue ? formatters.PhoneIncomplete(inheritedValue) : ""}
            onBeforeInputCapture={(e: any) => {
                if (!/^[0-9()-. ]+$/.test(e.data)) {
                    e.preventDefault();
                } else if (onBeforeInputCapture) {
                    onBeforeInputCapture(e);
                }
            }}
            onChange={(e: any ) => { //Correct type React.ChangeEvent<HTMLInputElement>
                let caret = e.target.value.length - e.target.selectionStart;
                let value = e.target.value.replace(/\D+/g, "");
                let inheritedDigits = inheritedValue?.replace(/\D+/g, "") || "";
                if (inheritedDigits.length === 10 && value.length > 10) {
                    // cap at 10 digits, don't overwrite if caret is moved
                    e.target.value = formatters.PhoneIncomplete(inheritedDigits);
                } else if (e.target.value.charAt(0) === "0" || e.target.value.charAt(0) === "1") {
                    // don't allow 0 or 1 at start of number, it messes up a 10 digit format
                    e.target.value = e.target.value.substring(1, e.target.value.length);
                } else if (inheritedDigits !== value) {
                    e.target.value = formatters.PhoneIncomplete(value);
                    setPhoneValue(e.target.value);
                    if (onChange) onChange(e);
                } else if (phoneValue !== e.target.value) {
                    setPhoneValue(e.target.value);
                }
                caret = e.target.value.length - caret;
                e.target.setSelectionRange(caret, caret);
            }}
            onBlur={(e: any) => {
                let value = e.target.value.replace(/\D+/g, "");
                e.target.value = formatters.PhoneIncomplete(value);
                if (phoneValue !== e.target.value) setPhoneValue(e.target.value);
                if (onBlur) onBlur(e);
            }}
            {...props}
        />
    );
}
