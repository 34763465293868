import {Close, Visibility, VisibilityOff} from "@mui/icons-material";
import {Box, Button, Dialog, DialogActions, DialogContent, IconButton, InputAdornment, Link, MenuItem, Typography} from "@mui/material";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";
import {sleep} from "../../util/Sleep";
import CCInputLabel from "../Common/CCInputLabel";
import CCPulse from "../Common/CCPulse";
import CCSelect from "../Common/CCSelect";
import CCTextField from "../Common/CCTextField";
import css_self from "./css/LoginMain.module.scss";
import "./css/LoginMain.scss";
import {useLoginContext} from "./Login";
import enlilImage from "../../assets/logo-5cd08655.svg";
import {Blue3} from "../../theme/styles";
import useAppContext from "../../util/AppContext";

export default function LoginMain() {

    const {sessionContext} = useAppContext();

    const [submitting, setSubmitting] = useState(false);
    const [errorText, setErrorText] = useState(undefined);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const [companies, setCompanies] = useState([]);
    const [companyUSID, setCompanyUSID] = useState("");
    const [displayedCompany, setDisplayedCompany] = useState("");
    const [displayedCompanies, setDisplayedCompanies] = useState([]);

    const {
        updatePreSessionState,
        login,
    } = useLoginContext();

    useEffect(() => {
        updatePreSessionState(state => {
            return {
                ...state,
                email: email,
                password: password,
                company: companyUSID
            };
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, password, companyUSID]);

    async function submit() {

        const startAt = Date.now();

        const waitVisualMin = async () => {
            const waitDiff = 2000 - (Date.now() - startAt);
            waitDiff > 0 && (await sleep(waitDiff));
        };

        setSubmitting(true);

        const response = await login();

        if (response instanceof Error && response.message === "Network Error") {
            setSubmitting(false);
            setErrorText("Connection not available");
            return;
        }

        // maintenance outage is in effect
        if (response.status === 503 && response.data.Code === "CC-CF78-4C2A") {
            window.location.reload();
            return;
        }

        if (response.status !== 200) {

            await waitVisualMin();

            setSubmitting(false);

            if (response.status === 429) {
                if (response.data.Code === "CC-746A-4E73") {
                    setErrorText("Exceeded 5 failed login attempts in 24 hours");
                    return;
                }
            }

            if (response.status === 400) {

                if (response.data.Code === "CC-11C9-41C9") {
                    if (response.data["FailCount"] === 8) {
                        setErrorText("Invalid username or password. 8th consecutive failure. 2 more attempts allowed.");
                    } else if (response.data["FailCount"] === 9) {
                        setErrorText("Invalid username or password. 9th consecutive failure. 1 more attempt allowed.");
                    } else if (response.data["FailCount"] === 10) {
                        setErrorText("Invalid username or password. Account locked.");
                    } else {
                        setErrorText("Invalid username or password.");
                    }
                    return;
                }

                if (response.data.Code === "CC-1242-4AAC") {
                    setErrorText("User is deactivated. Please contact administrator.");
                    return;
                }
                if (response.data.Code === "CC-1242-4WWC") {
                    setErrorText("User has not confirmed their account. Please contact administrator.");
                    return;
                }
            }

            if (response.status === 401 || response.status === 403) {
                setErrorText("Invalid username or password");
            } else {
                setErrorText("Service temporarily unavailable");
            }

            return;
        }

        // multiple companies
        if (response.data.Code === "CC-ADC7-49A2" || response.data.Code === "CC-E5EB-43D5") {

            setSubmitting(false);

            const companies = response.data["Realms"];

            Object.keys(companies).forEach(k => {
                companies[k] = _.sortBy(companies[k], x => x["CompanyName"]);
            });

            setCompanies(companies);

            const displayedCompanies = Object.keys(companies).sort();
            const displayedCompany = displayedCompanies[0];

            setCompanyUSID(companies[displayedCompany][0]["CompanyUSID"])
            setDisplayedCompany(displayedCompany);
            setDisplayedCompanies(displayedCompanies);

            setShowPassword(false);

            return;
        }

        // noinspection UnnecessaryReturnStatementJS
        return;
    }

    let loginFormToPresent = null;

    if (displayedCompanies.length === 0) {
        loginFormToPresent = (
            <>
                <CCInputLabel>Email</CCInputLabel>
                <CCTextField
                    value={email}
                    placeholder="Enter your email"
                    onChange={async e => setEmail(e.target.value)}
                />
                <CCInputLabel>Password</CCInputLabel>
                <CCTextField
                    value={password}
                    placeholder="Enter your password"
                    type={showPassword ? "text" : "password"}
                    helperText={<span className="Mui-error" style={{visibility: errorText ? undefined : "hidden"}}>{errorText}</span>}
                    onChange={async e => setPassword(e.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={async () => setShowPassword(!showPassword)} onMouseDown={e => e.preventDefault()}>
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    {...{"data-cc-component": "CCLoginButton"}}
                    {...{"data-animate": submitting}}
                    fullWidth
                    disabled={
                        submitting || email.length === 0 || password.length === 0
                    }
                    onClick={() => {
                        setErrorText(undefined);
                        submit();
                    }}
                >
                    <span style={submitting ? {visibility: "hidden"} : undefined}>Login</span>
                    {submitting ? <CCPulse className={css_self.Pulse} /> : undefined}
                </Button>
            </>
        );
    }

    if (displayedCompanies.length >= 1) {

        loginFormToPresent = (
            <>
                <CCInputLabel>Company</CCInputLabel>
                <CCSelect
                    fullWidth
                    size={"small"}
                    value={displayedCompany}
                    onChange={e => {
                        setCompanyUSID(companies[e.target.value][0]["CompanyUSID"]);
                        setDisplayedCompany(e.target.value);
                    }}
                >
                    {displayedCompanies.map(company => (
                        <MenuItem value={company} key={`select-company-${company}`}>{company}</MenuItem>
                    ))}
                </CCSelect>
                <Button
                    {...{"data-cc-component": "CCLoginButton"}}
                    {...{"data-animate": submitting}}
                    fullWidth
                    disabled={ submitting }
                    onClick={() => {
                        setErrorText(undefined);
                        submit();
                    }}
                >
                    <span style={submitting ? {visibility: "hidden"} : undefined}>Select</span>
                    {submitting ? <CCPulse className={css_self.Pulse} /> : undefined}
                </Button>
            </>
        );
    }

    return (
        <Dialog maxWidth="md" open={sessionContext.showLoginDialog} scroll="body">
            <Box position="absolute" top={0} right={0}>
                <IconButton onClick={() => {
                    setEmail("")
                    setPassword("")
                    setDisplayedCompanies([]);
                    setCompanyUSID("");
                    setDisplayedCompany("");

                    sessionContext.setShowLoginDialog(false);
                    sessionContext.isLoggedIn = false;
                }}>
                  <Close/>
                </IconButton>
            </Box>
            <DialogContent style={{padding: "0 16px"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "center"}}>
                    <div>
                        <img className={css_self.EnlilLogo} src={enlilImage} alt="Home"/>
                    </div>
                    <div style={{marginTop: "10px"}}>
                        <span style={{marginLeft: "65px", fontSize: 25, fontWeight: 400, color: Blue3}}>| Developer</span>
                    </div>
                </div>
                <div style={{marginTop: "20px", marginBottom: "20px"}}>
                    <span style={{fontSize: 18, fontWeight: 400, color: "#000000"}}>Login to your account</span>
                </div>
                {loginFormToPresent}
                <Typography className={css_self.HelpText}>
                    <Link variant="inherit" color="inherit" component={RouterLink} to="/help" replace={true}>Trouble logging in?</Link>
                </Typography>
                {displayedCompanies.length > 0 ? (
                    <Typography className={css_self.HelpText}>
                        <Link variant="inherit" color="inherit" component={RouterLink} to="#" replace={true} onClick={() => window.location.reload()}>Start over</Link>
                    </Typography>
                ) : null}
            </DialogContent>
            <DialogActions>
            </DialogActions>
        </Dialog>
    );

}
