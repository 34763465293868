import React from "react";

import Applet from "../../Applet";
import DeveloperExceptionTable from "../../../../components/ExceptionManagement/DeveloperExceptionTable";

class DeveloperExceptionTableApplet extends Applet {

    render() {
        return <DeveloperExceptionTable applet={this}
                               pageSize={this.state.PageSize}
                               placement={this.state.Placement}
                               columns={this.state.Columns}
                               title="Exceptions"
                               enterpriseComponent={this.getEnterpriseComponent()}
                               sort={this.state.Sort}
                               menu={this.state.Menu}
                               rowHeight={this.state.RowHeight}
                               enableSort={true}
        />;
    }

    state = {
        Name: "DeveloperExceptionTableApplet",
        Menu: {
            options: [
                {
                    displayValue: "Refresh",
                    value: "Refresh",
                },
            ],
        },
        RowHeight: 75,
        PageSize: {
            "default": 40,
            "xs": 3,
            "sm": 3,
            "md": 10,
            "lg": 40,
            "lx": 40,
        },
        Placement: {
            "xl": [
                {
                    id: "RecordPointer",
                },
                {
                    id: "ExceptionType",
                },
                {
                    id: "ExceptionApplication",
                },
                {
                    id: "ExceptionAnalysis",
                },
                {
                    id: "ExceptionCode",
                },
                {
                    id: "ExceptionDatetime",
                }
            ]
        },
        Columns: {
            "RecordPointer": {
                id: "RecordPointer",
                width: 25,
                cellRenderer: "Row Selection Indicator Cell",
                header: null,
                headerRenderer: "",
                headerTitle: "",
            },
            "ExceptionCode": {
                id: "ExceptionCode",
                width: 150,
                cellRenderer: "Standard Single Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Code",
            },
            "ExceptionType": {
                id: "ExceptionType",
                width: 300,
                cellRenderer: "Exception Type Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Type",
            },
            "ExceptionApplication": {
                id: "ExceptionApplication",
                width: 150,
                cellRenderer: "Exception Application Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Application",
            },
            "ExceptionAnalysis": {
                id: "ExceptionAnalysis",
                width: 600,
                cellRenderer: "Exception Analysis Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Description",
            },
            "ExceptionDatetime": {
                id: "ExceptionDatetime",
                width: 260,
                cellRenderer: "Local Datetime Cell",
                header: "enabled",
                headerRenderer: "Standard Single Header",
                headerTitle: "Date Time",
            },
        }
    };
}

export default DeveloperExceptionTableApplet;
