import EnlilAxios from "../../../../util/EnlilAxios";
import {V1DeveloperEventIDOCToArray} from "../../../../util/Payload";
import EnterpriseComponent from "../../EnterpriseComponent";


class DeveloperEventEnterpriseComponent extends EnterpriseComponent {
    performQuery = (page, pageSize, foreignKey) => {
        page = page || 1;
        pageSize = pageSize || this.getDefaultCacheSize();

        this.notifyLoadStartListeners();

        let fk = {};
        if (this.enterpriseObject !== null) {
            let relationship = this.enterpriseObject.getRelationshipForEnterpriseComponent(this);

            if (relationship) {
                fk[relationship.getDestinationAttribute()] = foreignKey;
            }
        }

        EnlilAxios({
            method: "GET",
            url: "t2/v1/developer_events",
            json: true,
            params: {
                "Limit": pageSize,
                "Offset": (page - 1) * pageSize,
                "Sort": this.getFormattedSortSpecification(),
                ...(foreignKey ? fk : {}),
                ...this.getSearchSpecification(),
            },
            headers: {
                "idoc-response": this.getIDOCResponse() ?? "v1.DeveloperEvent-001",
            },
        }).then(response => {
            this.fetchEnterpriseComponentSuccess(V1DeveloperEventIDOCToArray(response.data), page, pageSize);
        }).catch(error => {
            this.fetchEnterpriseComponentFailed(error);
        });
    };

    enterpriseComponentPutIDOC = () => {
        let payload = {};

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperEvent-001";
        payload.ShifamedMessage.DeveloperEvent = this.preparePUTPayload();

        return payload;
    };

    performUpdate() {
        let payload = this.enterpriseComponentPutIDOC();

        let options = {
            method: "PUT",
            url: "t2/v1/developer_events",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.DeveloperEvent-001",
                "idoc-response": "v1.DeveloperEvent-001",
            },
        };
        this.notifyUpdateStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.updateEnterpriseComponentSuccess(V1DeveloperEventIDOCToArray(response.data));
            })
            .catch(error => {
                this.updateEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentPostIDOC = () => {
        let payload = {};

        let developerEvent = {};

        developerEvent["USID"] = this.getAttributeValue("USID");
        developerEvent["EventType"] = this.getAttributeValue("EventType");
        developerEvent["DeveloperEndpointUSID"] = this.getAttributeValue("DeveloperEndpointUSID");
        developerEvent["Environment"] = this.store["Environment"];

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperEvent-001";
        payload.ShifamedMessage.DeveloperEvent = developerEvent;

        return payload;
    };

    performInsert() {
        let payload = this.enterpriseComponentPostIDOC();

        let options = {
            method: "POST",
            url: "t2/v1/developer_events",
            json: true,
            data: payload,
            headers: {
                "idoc-request": "v1.DeveloperEvent-001",
                "idoc-response": "v1.DeveloperEvent-001",
            },
        };
        this.notifyInsertStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.insertEnterpriseComponentSuccess(V1DeveloperEventIDOCToArray(response.data));
            })
            .catch(error => {
                this.insertEnterpriseComponentFailed(error);
            });
    };

    enterpriseComponentDeleteIDOC = () => {
        let payload = {};

        let developerEvent = {};

        developerEvent["USID"] = this.getAttributeValue("USID");

        payload.ShifamedMessage = {};
        payload.ShifamedMessage["-idoc"] = "v1.DeveloperEvent-001";
        payload.ShifamedMessage.DeveloperEvent = developerEvent;

        return payload;
    };

    performDelete() {
        let payload = this.enterpriseComponentDeleteIDOC();

        let options = {
            method: "DELETE",
            url: "t2/v1/developer_events",
            json: true,
            data: payload,
            headers: {},
        };
        this.notifyDeleteStartListeners();
        EnlilAxios(options)
            .then(response => {
                this.deleteEnterpriseComponentSuccess();
            })
            .catch(error => {
                this.deleteEnterpriseComponentFailed(error);
            });
    };
}

export default DeveloperEventEnterpriseComponent;