import {TextField} from "@mui/material";
import React, {ReactElement, useState} from "react";
import "./css/CCInputLabel.scss";

export default function CCInputFloat({
    fullWidth, // true | false
    variant, // standard | outlined | filled
    onBeforeInputCapture,
    onChange,
    onBlur,
    onKeyDown,
    onKeyUp,
    inputProps = {},
    children,
    ...props
}: {
    fullWidth?: boolean;
    variant?: "filled" | "outlined" | "standard";
    onBeforeInputCapture?: any;
    onChange?: any;
    onBlur?: any;
    onKeyDown?: any;
    onKeyUp?: any;
    inputProps?: any;
    children?: ReactElement;
}) {

    if (inputProps.type === undefined) inputProps.type = "number";

    const [keyDown, setKeyDown] = useState("");

    function format(e: any) {

        let step = parseFloat(e.target.step);
        let min = parseFloat(e.target.min);
        let max = parseFloat(e.target.max);
        let value = parseFloat(e.target.value);

        let decimals: number = e.target.step.split(".")[1]?.length || 0;

        if (!isNaN(step) && value !== Math.floor(value)) { // skip integers
            let precision = parseFloat("1" + ("0".repeat(decimals)));
            let prestep = value;
            value = value - (((value - Math.floor(value)) * precision) % (step * precision)) / precision;
            if (value < prestep) value += step; // round up to next step because it feels more friendly that way
            if (value === Math.floor(value)) value -= step; // round down to previous step if we hit an integer
            value = parseFloat(value.toFixed(decimals));
        }

        if (!isNaN(min)) value = Math.max(min, value);

        if (!isNaN(max)) value = Math.min(max, value);

        return isNaN(value) ? "" : value.toFixed(decimals);
    }

    function clamp(e: any) {

        let min = parseFloat(e.target.min);
        let max = parseFloat(e.target.max);
        let value = parseFloat(e.target.value);

        let decimals = e.target.step.split(".")[1]?.length || 0;

        if (!isNaN(min) && value <= min) return min.toFixed(decimals);
        if (!isNaN(max) && value >= max) return max.toFixed(decimals);

        return e.target.value;
    }

    return (
        <TextField
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            variant={variant !== undefined ? variant : "outlined"}
            inputProps={inputProps}
            onBeforeInputCapture={(e: any) => {
                if (e.data.includes(".") && e.target.value.includes(".")) {
                    e.preventDefault();
                } else if (!/^[.0-9]+$/.test(e.data)) {
                    e.preventDefault();
                } else if (onBeforeInputCapture) {
                    onBeforeInputCapture(e);
                }
            }}
            onChange={e => {
                if (["ArrowUp", "ArrowDown"].includes(keyDown)) {
                    // we are stepping up or down with arrow keys, so force format
                    e.target.value = format(e);
                } else if (["Delete", "Backspace"].includes(keyDown)) {
                    // ignore format when deleting characters
                } else if (e.target.value.includes(".") && !e.target.value.endsWith(".")) { // safari: parseFloat will consume the period
                    // we have a "." now, so force format
                    e.target.value = format(e);
                } else {
                    // if we are typing numbers with no "." yet, only clamp to min/max and don't force format yet
                    e.target.value = clamp(e);
                }
                if (onChange) onChange(e);
            }}
            onBlur={(e: any) => {
                const type = e.target.type;
                const value = e.target.value;
                e.target.type = undefined; // chrome: disable number type to get rid of "."
                e.target.value = format(e);
                e.target.type = type;
                if (value !== e.target.value) {
                    if (onChange) onChange(e);
                }
                if (onBlur) onBlur(e);
            }}
            onKeyDown={e => {
                setKeyDown(e.key);
                if (onKeyDown) onKeyDown(e);
            }}
            onKeyUp={e => {
                setKeyDown("");
                if (onKeyUp) onKeyUp(e);
            }}
            {...props}
        />
    );
}