import {TextField, TextFieldProps} from "@mui/material";
import React from "react";
import "./css/CCInputLabel.scss";

type CCInputIntegerProps = TextFieldProps;

export default function CCInputInteger({
    fullWidth,
    variant,
    onBeforeInputCapture,
    onChange,
    onBlur,
    inputProps = {},
    children,
    ...props
}: CCInputIntegerProps) {

    if (inputProps.type === undefined) inputProps.type = "number";

    function format(e: any) {

        let step = parseInt(e.target.step);
        let min = parseInt(e.target.min);
        let max = parseInt(e.target.max);
        let value = parseInt(e.target.value);

        if (!isNaN(step)) {
            let prestep = value;
            value = value + ((value - (!isNaN(min) ? min : 0)) % step);
            if (value < prestep) value += step; // round up to next step because it feels more friendly that way
        }

        if (!isNaN(min)) value = Math.max(min, value);

        if (!isNaN(max)) value = Math.min(max, value);

        return isNaN(value) ? "" : value.toString();
    }

    return (
        <TextField
            fullWidth={fullWidth !== undefined ? fullWidth : true}
            variant={variant !== undefined ? variant : "outlined"}
            inputProps={inputProps}
            onBeforeInputCapture={(e: any) => {
                if (!/^\d+$/.test(e.data)) {
                    e.preventDefault();
                } else if (onBeforeInputCapture) {
                    onBeforeInputCapture(e);
                }
            }}
            onChange={e => {
                e.target.value = format(e);
                if (onChange) onChange(e);
            }}
            onBlur={e => {
                const value = e.target.value;
                e.target.value = format(e);
                if (value !== e.target.value) {
                    if (onChange) onChange(e);
                }
                if (onBlur) onBlur(e);
            }}
            {...props}
        />
    );
}
