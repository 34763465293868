import {FormControl, Select, SelectProps} from "@mui/material";
import "./css/CCSelect.scss";

type CCSelectLocalProps = {
    elevation?: number;
}

type CCSelectProps = CCSelectLocalProps & SelectProps;

export default function CCSelect({
    fullWidth,
    size,
    elevation, // 0-24
    MenuProps,  //Not used
    children,
    ...props
}: CCSelectProps) {
    return (
        <FormControl
            fullWidth={fullWidth}
            size={size}
        >
            <Select
                MenuProps={{
                    PaperProps: {
                        elevation: elevation !== undefined ? elevation : 2,
                    },
                    ...MenuProps,
                }}
                {...props}
            >
                {children}
            </Select>
        </FormControl>
    );
}
