import React from "react";
import "../Navigation/css/NavBar.scss";
import {Black} from "../../theme/styles";
import part_003_put_attachment_request_1 from '../../json/part-003-put-attachment-request-1.json';
import part_003_put_attachment_response_1 from '../../json/part-003-put-attachment-response-1.json';
import ReactJson from 'react-json-view'

export default function ApprovalRequestsPut() {

    return (
        <div style={{paddingLeft: "20px", paddingRight: "30px"}}>
            <p style={{fontSize: "24px", fontWeight: "400", color: Black}}>Parts PUT</p>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Overview</p>

            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                In Enlil, the parts PUT API allows for the update of part content.
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Parameters</p>
            <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <li style={{marginTop: "20px"}}>Header Parameters
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-AccessToken (Required String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-AccessToken is obtained during the authentication process and expires after 90 days.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>X-Enlil-RefreshToken (Optional String)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The X-Enlil-RefreshToken is obtained during the authentication process and never expires. This token is provided is the caller wants a refreshed X-Enlil-AccessToken</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-request (Required String: must equal v1.Part-002)</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>The name of the IDOC being specified in the PUT request.</li>
                    <li style={{marginTop: "20px", marginLeft: "20px"}}>idoc-response (Optional String).</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>v1.Part-001, v1.Part-002 and v1.Part-003 are permitted values.</li>
                    <li style={{marginTop: "10px", marginLeft: "40px"}}>If idoc-response is provided, the response payload (HTTP Code: 201) will match the specified format, else no response (HTTP Code: 204) will be provided.</li>
                </li>
            </ul>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Scenarios</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black, marginLeft: "20px"}}>
                <span style={{lineHeight: "1.5em"}}>
                Enlil requires Part PUT content to be in the v1.Part-003 format. For example, the user executes this PUT using idoc-request=v1.Part-003 (below) with idoc-response=v1.Part-003, Enlil will respond (HTTP Code: 200) with a payload of the
                v1.Part-003 below format<br/>
                </span>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>Adding an attachment to a part
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>Items represent the scope of the AR. Any number of items may be added provided the AR is in a DRAFT state. To add one or more items to an AR submit the following representative payload.</li><br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_003_put_attachment_request_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                        <br/>
                        <li style={{marginTop: "20px", marginLeft: "20px"}}>The following is a representative response.</li>
                        <br/>
                        <p style={{fontFamily: "Courier New", fontSize: "12px", marginLeft: "20px"}}>
                            <ReactJson src={part_003_put_attachment_response_1} collapseStringsAfterLength={75} collapsed={4}/>
                        </p>
                    </li>
                </ul>
            </p>
            <br/>
            <p style={{fontSize: "16px", fontWeight: "400", color: Black}}>Business Rules</p>
            <p style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                <ul style={{fontSize: "14px", fontWeight: "400", color: Black}}>
                    <li style={{marginTop: "20px"}}>Document ID is not updatable."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-E250-7457</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The attribute 'Document ID' in enterprise component 'Part' has the 'No Update' attribute set to 'true', therefore cannot be updated.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                    <li style={{marginTop: "20px"}}>Document Type USID is not updatable."
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Error Code: CC-E250-7457</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>Description: The attribute 'Document Type ID' in enterprise component 'Part' has the 'No Update' attribute set to 'true', therefore cannot be updated.</li>
                        <li style={{marginTop: "10px", marginLeft: "40px"}}>HTTP Code: 400</li>
                    </li>
                </ul>
            </p>
        </div>
    )
}